<template>
  <v-container>
    <v-main>
      <v-form>
        <v-row>
          <v-text-field
              :label="$t('Login')"
              v-model="user.email"
              @keydown.enter="connect"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
              :label="$t('Password')"
              v-model="user.password"
              type="password"
              @keydown.enter="connect"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-btn
              :disabled="user.email === '' || user.password === ''"
              block
              color="primary"
              elevation="2"
              large
              @click="connect"
          >{{ $t('Connect') }}</v-btn>
        </v-row>
      </v-form>
      <v-snackbar
        v-model="showErrorMsg">{{ errorMsg }}</v-snackbar>
    </v-main>
  </v-container>
</template>

<script>
import DataRequest from '../libs/DataRequest';

export default {
  name: 'Login',
  data: () => ({
    user: {
      email: '',
      password: '',
    },
    errorMsg: '',
    showErrorMsg: false,
  }),
  methods: {
    connect() {
      DataRequest.post('/login', this.user, (result) => {
        DataRequest.setApiToken(result.apiToken);
        this.$router.push('/admin');
      }, (errorResponse) => {
        this.errorMsg = errorResponse.data.message;
        this.showErrorMsg = true;
      });
    },
  },
};
</script>

<style scoped>

</style>
